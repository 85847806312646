<template>
      

    <template v-if="week">
      <WeekwideViewFazaGerm v-if="week.faza == -1" :week="week" :diary="diary" @removeweek="emits('removeweek', week.id)"/>      
      <WeekwideViewFazaVeg v-if="week.faza == 0" :week="week" :diary="diary" @removeweek="emits('removeweek', week.id)"/>      
      <WeekwideViewFazaFlo v-if="week.faza == 1" :week="week" :diary="diary" @removeweek="emits('removeweek', week.id)"/>      
      <WeekwideViewFazaHar v-if="week.faza == 2" :week="week" :diary="diary" @removeweek="emits('removeweek', week.id)"/>   
    </template> 
    <template v-if="!week && diary">
      <WeekwideViewFazaEmpty :owner="diary.item_user.id" @choosecreate="emits('choosecreateweek')"/>      
    </template> 


</template>





<script setup>

const emits = defineEmits(['routedweek', 'choosecreateweek', 'removeweek']);
const route = useRoute();
const props = defineProps({
  week: {
    type: Object    
  },
  diary: {
    type: Object,            
    required: true,
  }
});

// var week_id = ref(route.params['week']);

// watch(route.params['week'], (new_week, old_week) => {
//   console.log('routedWeek');
//   emits('routedweek', new_week)
// })



</script>

 

<style scoped>

 

</style>
